/************************************ this file include all pages style ******************************/
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: "Assistant", sans-serif;
}

.navbar {
  width: 100%;
  height: 70px;
  align-items: center;
  justify-content: center;
  background-color: #3bb19b;
  display: flex;
  align-items: center;
}

.navbar h1 {
  color: white;
  font-size: 30px;
  margin-left: 20px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.table_container {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
}

.search_label {
  display: flex;
  justify-content: center;
  align-content: center;
}

.search_bar {
  width: 80%;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 25px;
  padding: 0 40px 0 10px;
  font-size: 16px;
  margin-top: 20px;
  background-color: white;
}

.main_button {
  height: 42px;
  background-color: #3bb19b;
  color: #fff;
  border: none;
  border-radius: 20px;
  padding: 0 15px;
  font-size: 18px;
  font-weight: 400;
  cursor: pointer;
  transition: background-color 0.3s;
  margin: 10px 0px 2px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}

.main_button:hover {
  background-color: #1ad5b3;
}

.button {
  height: 40px;
  background-color: #3bb19b;
  color: #fff;
  border: none;
  border-radius: 20px;
  padding: 0 15px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin: 0px 2px 10px 2px;
}

.cards_container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  box-sizing: border-box;
}

.card {
  display: flex;
  background-color: #3bb19b;
  border-radius: 20px;
  margin: 5px;
  padding: 0 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 160px;
  min-width: 130px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  transition: background-color 0.2s;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}

.card:hover {
  background-color: #1ad5b3;
}

.card_title {
  color: #fff;
  font-size: inherit;
  font-weight: 600;
}

.card_content {
  color: #f0f0f0;
  margin: 0;
}

.search_results {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.table {
  margin: 10px auto;
}

.link_button {
  display: flex;
  align-items: center;
  justify-content: center;
}

